import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Slider() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const onChange = (index) => {
    setCurrentSlide(index);
  };

  const onClickItem = (index) => {
    console.log(`Clicked item ${index}`);
  };

  // const onClickThumb = (index) => {
  //     console.log(`Clicked thumbnail ${index}`);
  // };
  return (
    <Carousel
      showArrows={true}
      selectedItem={currentSlide}
      onChange={onChange}
      onClickItem={onClickItem}
      showThumbs={false} // Hide thumbnails
      autoPlay={true}
      interval={30000000}
      infiniteLoop={true}
      stopOnHover={true}
    >
      <div>
        <img src="./images/SliderImg1.jpg" alt="" />
        <p className="legend">
          <span>Strategic Growth:</span> Focusing on scalability to grow with
          your business needs.
        </p>
      </div>
      <div>
        <img src="./images/SliderImg2.jpg" alt="" />
        <p className="legend">
          <span>Robust Development:</span> Building solid foundations with
          cutting-edge technologies.
        </p>
      </div>
      <div>
        <img src="./images/SliderImg3.jpg" alt="" />
        <p className="legend">
          <span>Strategic Insights:</span> Aligning your business goals with
          digital trends.
        </p>
      </div>
      <div>
        <img src="./images/SliderImg4.jpg" alt="" />
        <p className="legend">
          <span>Innovative Design:</span> Crafting visually stunning and
          user-friendly interfaces.
        </p>
      </div>
    </Carousel>
  );
}

export default Slider;
