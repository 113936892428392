import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function OurVision() {
  return (
    <Container fluid>
        <Row>
          <Col className="px-0 our-vision">
            <Container>
              <Row>
                <Col md={4}>
                <h2 className="subheading2">
                Our <span>Vision</span>
            </h2>
                </Col>
                <Col md={8}>
                
                    <p>Establishing itself as a beacon of excellence within the realm of technology consulting and software services, Stealth Code prides itself on its unparalleled technical prowess. Our company operates with a steadfast dedication to being at the forefront of innovation, consistently striving to set new standards of technical excellence and expertise.</p>

                    <p>At the heart of our success lies a team of highly skilled professionals who possess a deep understanding of cutting-edge technologies and possess a wealth of experience in their respective fields. These individuals are not merely employees; they are passionate problem solvers and innovators who are committed to pushing the boundaries of what is possible in the world of technology.</p>
                     </Col>
              </Row>
            </Container>
         
          </Col>
        </Row>
      </Container>
  )
}

export default OurVision
