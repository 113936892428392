import React from "react";
import Slider from "./Slider";
import "./Home.css";
import HomeContent from "./HomeContent";

function Home() {
  return (
    <>
      <Slider />
      <HomeContent />
    </>
  );
}

export default Home;
