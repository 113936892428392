import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function Contact() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12} className="p-0">
            <img
              src="./images/contactus.jpg"
              className="img-fluid"
              alt="Contact Us"
            />
            <Container>
              <Row>
                <Col md={12}>
                  <h1 className="heading">Contact Us</h1>
                  <h2 className="subheading">
                    Get in <span>touch with us!</span>
                  </h2>
                  <p>Contact text here............</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
