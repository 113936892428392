import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "./NavBar";

const Header = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setHeaderHeight(100);
      } else {
        setHeaderHeight(Math.max(0, headerHeight - 1));
      }
      setLastScrollTop(scrollTop);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header>
      <Container fluid>
        <Row>
          <Col
            className="header-bg"
            style={{ height: `${headerHeight}px` }}
          ></Col>
        </Row>
      </Container>
      <Container className="fixed-top">
        <Row>
          <Col xs={4} md={2}>
            <a href="/"><img src="./images/logo.png" alt="Logo" height={100} /></a>
          </Col>
          <Col xs={8} md={10}>
            <Navbar></Navbar>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
