import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Components/header/Header";
import Home from "./Components/home/Home";
import About from "./Components/about/About";
import Contact from "./Components/contact/Contact";
import Footer from "./Components/footer/Footer";
import WhatWeDo from "./Components/whatwedo/WhatWeDo";
import OurVision from "./Components/our-vision/OurVision";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/whatwedo" element={<WhatWeDo />} />
        </Routes>
        <OurVision />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
