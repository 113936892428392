import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function HomeContent() {
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <h2 className="subheading">
              Who <span>Are We</span>
            </h2>
            <Container>
              <Row>
                <Col md={8} className="p-0">
                  <p>
                    Stealth Code stands at the forefront of the technology
                    landscape as a premier Technology Consulting and Software
                    firm, dedicated to pioneering solutions and services in
                    Software Engineering and Cloud computing.
                  </p>

                  <p>
                    Our foundation rests on unwavering principles of Integrity,
                    Transparency, and Quality, infusing every endeavor with a
                    commitment to excellence. These values serve as beacons,
                    illuminating our path as we consistently exceed expectations
                    and elevate industry standards.
                  </p>

                  <p>
                    Through our unwavering dedication, we've cultivated a
                    reputation as a reliable partner, trusted by clients
                    worldwide. Our track record speaks volumes, showcasing our
                    ability to deliver innovative solutions that transcend
                    geographical boundaries.
                  </p>

                  <p>
                    At Stealth Code, we don't just meet expectations; we surpass
                    them. Our mission is to empower clients to realize their
                    objectives and drive tangible business outcomes.
                  </p>
                </Col>
                <Col md={4} className="p-0">
                  <img
                    src="./images/WhoWeAre.jpg"
                    alt="Who We Are"
                    className="img-fluid"
                  />
                </Col>
              </Row>
            </Container>

            <h2 className="subheading">
              What <span>We Do</span>
            </h2>
            <Container className="p-0">
              <Row>
                <Col md={3}>
                  <img
                    src="./images/WhatWeDo.jpg"
                    alt="What We Do"
                    className="img-fluid"
                  />
                </Col>
                <Col md={9}>
                  <p>
                    At our core, we are committed to fueling businesses towards
                    unprecedented success within today’s dynamic and
                    technologically driven landscape. Our array of services is
                    meticulously crafted to resonate with the pulse of the
                    modern business sphere, and we stand tall as pioneers of
                    digital innovation.
                  </p>
                  <p>
                    We take immense pride in our ability to navigate the
                    ever-evolving digital terrain with finesse, constantly
                    pushing boundaries to redefine industry standards. With a
                    comprehensive suite of offerings, we possess the acumen and
                    agility to meet your multifaceted needs head-on.
                  </p>
                  <p>
                    Partner with us to embark on a journey of transformation,
                    where innovation meets excellence, and together, we'll
                    unlock boundless opportunities for your business to thrive.
                  </p>
                  <ul className="list-items">
                    <li>Web Development</li>
                    <li>Digital Marketing</li>
                    <li>Cloud Services</li>
                    <li>Software Engineering</li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className="px-0">
            <Container>
              <Row>
                <Col md={12}>
                  <h2 className="subheading">
                    Why <span>Stealth Code</span>
                  </h2>

                  <p>
                    At the heart of our mission lies a commitment to delivering
                    unparalleled technology consulting services and solutions
                    tailored to the unique needs of our clients. We endeavor to
                    earn the esteemed position of being our clients' most
                    trusted advisor, achieved through our unwavering dedication
                    to providing solutions that align seamlessly with their
                    objectives.
                  </p>
                  <p>
                    Transparency is the cornerstone of our approach, guiding
                    every interaction and decision we make. We believe in
                    fostering open communication and operating with utmost
                    integrity, ensuring that our clients have full visibility
                    into the processes and strategies that drive their success.
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HomeContent;
