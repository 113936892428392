import React from 'react'
import { NavLink } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './Footer.css'

function Footer() {
  return (
    <footer className='footer'>
    <Container>
        <Row>
            <Col md={12} className='text-start'>
            <ul className='footer-nav'>
                    {/* <li><a to="/">Home</a></li>
                    <li><a to="/about">About Us</a></li>

                   <li><a to="/whatwedo">What We Do</a></li>
                    <li><a to="/contact">Contact Us</a></li> */}
                    <NavLink exact to="/">
              Home
            </NavLink>
            <NavLink to="/about">About Us</NavLink>
            <NavLink to="/whatwedo">What We Do</NavLink>
            <NavLink to="/contact">Contact Us</NavLink>
                </ul>
            </Col>
        </Row>
    </Container>
    <div className='site-info'>Copyright &copy; 2024 Stealth Code. All rights reserved.</div>
    </footer>
  )
}

export default Footer
