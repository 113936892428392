import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function About() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12} className="p-0">
            <img
              src="./images/aboutus.jpg"
              className="img-fluid"
              alt="About Us"
            />
            <Container>
              <Row>
                <Col md={12}>
                  <h1 className="heading">About Us</h1>
                  <h2 className="subheading">
                    Who <span>Are We</span>
                  </h2>
                  <p>
                    Stealth Code stands at the forefront of the technology
                    landscape as a premier Technology Consulting and Software
                    firm, dedicated to pioneering solutions and services in
                    Software Engineering and Cloud computing.
                  </p>

                  <p>
                    Our foundation rests on unwavering principles of Integrity,
                    Transparency, and Quality, infusing every endeavor with a
                    commitment to excellence. These values serve as beacons,
                    illuminating our path as we consistently exceed expectations
                    and elevate industry standards.
                  </p>

                  <p>
                    Through our unwavering dedication, we've cultivated a
                    reputation as a reliable partner, trusted by clients
                    worldwide. Our track record speaks volumes, showcasing our
                    ability to deliver innovative solutions that transcend
                    geographical boundaries.
                  </p>

                  <p>
                    At Stealth Code, we don't just meet expectations; we surpass
                    them. Our mission is to empower clients to realize their
                    objectives and drive tangible business outcomes.
                  </p>

                  <h2 className="subheading">
                    Our <span>Core Values</span>
                  </h2>
                  <ul className="list-items">
                    <li>
                      Consistently prioritize customer-centric solutions above
                      all else.
                    </li>
                    <li>
                      Provide outstanding customer service experiences
                      consistently.
                    </li>
                    <li>
                      Maintain a culture of transparency and integrity in every
                      interaction.
                    </li>
                    <li>
                      Embrace perpetual learning as an inherent part of our
                      ethos.
                    </li>
                    <li>
                      Demonstrate empathy and respect towards all stakeholders,
                      both internal and external.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default About;
