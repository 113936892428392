import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function WhatWeDo() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12} className="p-0">
            <img
              src="./images/WhatWeDoImg.jpg"
              className="img-fluid"
              alt="What We DO"
            />
            <Container>
              <Row>
                <Col md={12}>
                  <h1 className="heading">What We Do</h1>
                  <h2 className="subheading">
                    What <span>We Do</span>
                  </h2>
                  <p>
                    At the core of our ethos lies an unwavering dedication to
                    empowering businesses to achieve unparalleled success in
                    today's dynamic and technologically driven landscape. We
                    recognize the intricate nuances of the modern business
                    sphere and have meticulously crafted a diverse range of
                    services that align perfectly with its pulse. As pioneers of
                    digital innovation, we stand at the forefront, leading the
                    charge towards transformative change.
                  </p>
                  <p>
                    Our pride stems from our ability to navigate the
                    ever-evolving digital terrain with finesse. We thrive on the
                    challenge of pushing boundaries and redefining industry
                    standards, continuously seeking innovative solutions to meet
                    the evolving needs of our clients. Our comprehensive suite
                    of offerings is a testament to our commitment to excellence,
                    equipped with the acumen and agility to address the
                    multifaceted challenges faced by businesses today.
                  </p>
                  <p>
                    Partnering with us means embarking on a journey of
                    transformation, where innovation converges with excellence.
                    Together, we'll explore boundless opportunities and chart a
                    course towards unprecedented growth and success for your
                    business. With our expertise and collaborative approach,
                    we'll navigate the complexities of the digital landscape,
                    unlocking new possibilities and propelling your business
                    towards its full potential.
                  </p>

                  <ul className="list-items">
                    <li>
                      <strong>Web Development: </strong>
                      Here's an extended version focusing specifically on web
                      development: In the realm of web development, our
                      commitment to excellence shines brightly, guiding
                      businesses towards digital prominence in today's
                      ever-evolving landscape. Armed with a wealth of experience
                      and a deep understanding of industry trends, we specialize
                      in crafting bespoke web solutions that elevate brands and
                      drive meaningful engagement. Our approach to web
                      development is rooted in innovation and driven by a
                      relentless pursuit of perfection. From intuitive user
                      interfaces to robust backend systems, we leverage
                      cutting-edge technologies and best practices to deliver
                      exceptional results that exceed expectations.
                    </li>
                    <li>
                      <strong>Digital Marketing: </strong>Our approach to
                      digital marketing is grounded in data-driven insights and
                      fueled by creativity. We harness the power of analytics to
                      uncover hidden opportunities, identify audience segments,
                      and optimize campaigns for maximum impact. From search
                      engine optimization (SEO) and pay-per-click (PPC)
                      advertising to social media management and content
                      marketing, we offer a comprehensive suite of services
                      designed to elevate your brand's online presence.
                    </li>
                    <li>
                      <strong>Cloud Services: </strong>In today's
                      digitally-driven landscape, harnessing the power of cloud
                      services is essential for businesses seeking agility,
                      scalability, and efficiency. At the forefront of this
                      technological revolution, we offer a comprehensive suite
                      of cloud services designed to propel businesses towards
                      unprecedented heights of success. Our cloud services
                      encompass a wide array of offerings, from infrastructure
                      as a service (IaaS) and platform as a service (PaaS) to
                      software as a service (SaaS) and beyond. Whether you're
                      looking to migrate your existing infrastructure to the
                      cloud, develop custom applications, or leverage advanced
                      analytics and machine learning capabilities, we have the
                      expertise and resources to meet your needs. But our
                      commitment to excellence goes beyond mere technical
                      capabilities. We understand that successful cloud adoption
                      requires a holistic approach that encompasses people,
                      processes, and technology. That's why we work closely with
                      our clients to develop tailored strategies that align with
                      their business objectives and drive meaningful outcomes.
                    </li>
                    <li>
                      <strong>Software Engineering: </strong>At our core, we are
                      champions of software engineering excellence, driven by a
                      passion for innovation and a relentless pursuit of
                      perfection. From conceptualization to deployment and
                      beyond, we approach each project with meticulous attention
                      to detail and a commitment to delivering solutions that
                      exceed expectations. Our team of software engineers is a
                      diverse blend of talent, bringing together expertise in a
                      wide range of programming languages, frameworks, and
                      methodologies. Whether it's developing scalable web
                      applications, building robust backend systems, or crafting
                      intuitive mobile experiences, we have the skills and
                      experience to tackle even the most complex challenges.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default WhatWeDo;
